@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";
@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/themes/cao/imports";


$cao-primary: #9ad6f1;
$name: '.MainNavigation';

#{$name} {
    background-color: $white;

    @include media-breakpoint-up(xl) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $zindex-fixed;
        background-color: transparent;
    }

    &-navbar {
        background-color: transparent;
        transition: background-color .25s ease-in-out;
        padding: 1rem;

        @include media-breakpoint-up(xl) {
            padding: 1.5rem 0;
        }

        .nav-link,
        .Social a,
        #{$name}-brand {
            @include media-breakpoint-up(xl) {
                color: $white;
            }
        }

        a {
            @include media-breakpoint-down(xl) {
                color: $cao-dark-blue;
            }
        }
    }

    .navbar-nav {
        justify-content: flex-end;
    }
    //&.is-transparent {
    //    #{$name}-navbar {
    //        background-color: transparent;
    //    }
    //
    //}
    //
    //&.is-scrolled {
    //    .Social a,
    //    #{$name}-brand {
    //        color: $primary;
    //    }
    //}
    &-marketSelector {
        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    &-dropdownMenu {
        left: 0;
        right: 0;
        border: none;
        padding: 0;
        position: static;
        float: none;

        @include media-breakpoint-up(xl) {
            padding: 0 1.5rem 1.5rem;
            box-shadow: 0 4px 8px -3px rgba(100, 100, 100, .5);
            position: absolute;
            left: unset;
        }

        &.is-visible {
            display: block;
        }
    }


    &-navbarCollapse {
        @include media-breakpoint-down(lg) {
            padding-top: 1rem;
        }
    }

    &-navItem {
        &--megaMenu {
            @include media-breakpoint-up(xl) {
                position: static;
            }
        }

        &.active {
            font-weight: 700;
        }
    }

    &-navLink {
        line-height: 1;

        &--megaMenuLanguage {
            display: flex !important;
            align-items: center;
            gap: .5rem;
            font-family: inherit !important;

            &:hover {
                text-decoration: underline;
            }

            img {
                height: 20px;
                width: 20px;
            }
        }

        @include media-breakpoint-up(xl) {
            &--megaMenu {
                &::after {
                    content: "";
                    height: 6px;
                    width: 9px;
                    background-size: 9px 6px;
                    background-repeat: no-repeat;
                    background-position: center;
                    @include icon-chevron-down($body-color);
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    display: inline-block;
                    vertical-align: 3px;
                    margin-left: .2rem;
                }

                &:hover, &:focus {
                    &::after {
                        @include icon-chevron-down($primary);
                    }
                }
            }
        }
    }

    &-brand {
        padding-top: 0;
        padding-bottom: 0;


        @include media-breakpoint-up(xl) {
            margin-right: 3rem;
        }

        svg {
            height: 3rem;
            width: auto;

            @include media-breakpoint-up(xl) {
                height: 5rem;
                width: auto;
            }
        }
    }

    &-brandImage {
        width: 100px;
        height: 27px;

        @include media-breakpoint-up(xl) {
            width: 150px;
            height: 40px;
        }
    }

    &-productsWrapper {
        @include media-breakpoint-up(xl) {
            border-right: 3px solid $sand-light;
            padding-top: 1rem;
        }
    }

    &-selectorWrapper {
        @include media-breakpoint-up(xl) {
            padding-top: 1rem;
        }
    }

    &-navLink {
        font-family: $headings-font-family;
        text-transform: uppercase;
        display: inline-block;

        @include media-breakpoint-up(xl) {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    &-toggleButton {
        position: absolute;
        right: 0;
        top: 7px;
        height: 20px;
        width: 30px;
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: center;
        @include icon-chevron-down($cao-dark-blue);
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover, &:focus {
            @include icon-chevron-down($cao-medium-blue);
        }
    }

    &-productsLvl1, &-productsLvl2, &-productsLvl3, &-selectorLvl1, &-selectorLvl2, &-selectorLvl3, &-megaLvl1, &-megaLvl2 {
        list-style-type: none;
        padding-left: 0;
        line-height: 1;

        @include media-breakpoint-up(xl) { // Make links in desktop "not clickable" if href starts with #
            &Link[href^="#"] {
                pointer-events: none;
            }
        }
    }

    &-megaLvl1 {
        @include media-breakpoint-up(xl) {
            column-count: 3;
            column-gap: 30px;
        }

        &Markets {
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-direction: column;
                padding-bottom: 0;

                #{$name}-megaLvl1Item {
                    border-bottom: 1px solid $gray-200;
                    padding-bottom: 1rem;

                    &:last-child {
                        border: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    &-productsLvl2 {
        @include media-breakpoint-up(xl) {
            column-count: 2;
            column-gap: 30px;
        }
    }

    &-megaLvl1 {
        &Link {
            @include media-breakpoint-up(xl) {
                margin-bottom: 1rem;
                display: block;
            }
        }
    }


    &-productsLvl1, &-selectorLvl1 {
        &Item {
            position: relative;
        }

        &Link {
            text-transform: uppercase;
            color: $body-color;
            font: 700 .85rem $headings-font-family;

            @include media-breakpoint-down(lg) {
                display: block;
                padding: .5rem 0 .5rem 10px;
            }
        }
    }

    &-productsLvl2, &-selectorLvl2, &-megaLvl1 {
        @include media-breakpoint-up(xl) {
            margin-top: 1rem;
        }

        &Item {
            position: relative;

            @include media-breakpoint-up(xl) {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        &Link {
            text-transform: uppercase;
            color: $gray-800;
            font: .85rem $headings-font-family;

            @include media-breakpoint-down(lg) {
                display: block;
                padding: .5rem 0 .5rem 20px;

                &--link {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    &-megaLvl2 {
        &Flag {
            width: 15px;
            height: 15px;
            margin: 5px 0;
        }

        &Markets {
            @include media-breakpoint-up(xl) {
                display: flex;
                flex-wrap: wrap;

                #{$name}-megaLvl2Item {
                    @include media-breakpoint-up(xl) {
                        width: 15%;
                        min-width: 180px;
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }

    &-productsLvl3, &-selectorLvl3, &-megaLvl2 {

        &Link {
            color: $primary;
            font: 1rem $font-family-sans-serif;

            @include media-breakpoint-down(lg) {
                display: block;
                padding: .5rem 0 .5rem 30px;
            }

            @include media-breakpoint-up(xl) {
                font-size: .875rem;
            }
        }
    }

    &-toggler {
        width: 30px;
        height: 25px;
        padding: 0;
        position: relative;
        margin: 0;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        border: none;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 50%;
            background: $cao-dark-blue;
            opacity: 1;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }

        span:nth-child(even) {
            left: 50%;
            border-radius: 0 9px 9px 0;
        }

        span:nth-child(odd) {
            left: 0;
            border-radius: 9px 0 0 9px;
        }

        span:nth-child(1), span:nth-child(2) {
            top: 0;
        }

        span:nth-child(3), span:nth-child(4) {
            top: 11px;
        }

        span:nth-child(5), span:nth-child(6) {
            top: 22px;
        }

        &[aria-expanded=true] span:nth-child(1), &[aria-expanded=true] span:nth-child(6) {
            transform: rotate(45deg);
        }

        &[aria-expanded=true] span:nth-child(2), &[aria-expanded=true] span:nth-child(5) {
            transform: rotate(-45deg);
        }

        &[aria-expanded=true] span:nth-child(1) {
            left: 5px;
            top: 7px;
        }

        &[aria-expanded=true] span:nth-child(2) {
            left: calc(50% - 5px);
            top: 7px;
        }

        &[aria-expanded=true] span:nth-child(3) {
            left: -50%;
            opacity: 0;
        }

        &[aria-expanded=true] span:nth-child(4) {
            left: 100%;
            opacity: 0;
        }

        &[aria-expanded=true] span:nth-child(5) {
            left: 5px;
            top: 12px;
        }

        &[aria-expanded=true] span:nth-child(6) {
            left: calc(50% - 5px);
            top: 12px;
        }
    }

    &-selectorLvl2Link--btn {
        font-weight: bold;
        color: $white !important;
    }

    &-search {
        @include media-breakpoint-down(lg) {
            display: block;
            margin: 0;
            padding: 0;

            .SearchBoxAutocomplete-q {
                max-width: none;
            }
        }
    }

    .Social {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin: 2rem 0;

        @include media-breakpoint-up(lg) {
            margin: 0 2rem;
            justify-content: unset;
        }

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            padding: .85rem;
            border-radius: 50%;
            background-color: $cao-primary;
        }

        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}
